@import "variables";

.u-color-green {
  color: $colorOk !important;
}

.u-color-warn {
  color: $colorWarn !important;
}

.u-color-error {
  color: $colorError !important;
}

.u-color-green--bold {
  color: $colorOk !important;
  font-weight: bold;
}

.u-color-warn--bold {
  color: $colorWarn !important;
  font-weight: bold;
}

.u-color-error--bold {
  color: $colorError !important;
  font-weight: bold;
}

.u-status-box {
  display: inline-block;
  text-align: center;
  border: none;
  font-size: 10px;
  font-weight: bold;
  background: gray;
  padding: 2px 10px;
  min-width: 75px;
  border-radius: 15px;
  text-transform: uppercase;

  &--wider-l{
    min-width: 110px;

  }

  &--ok {
    background-color: $colorOk;
  }

  &--warn {
    background-color: $colorWarn;
  }

  &--error {
    background-color: $colorError;
  }

  &--yellow {
    background-color: yellow;
    color: #7a8893;
  }

  &--purple {
    background-color: rebeccapurple;
  }

  &--blue {
    background-color: dodgerblue;
  }

  &--orange {
    background-color:orangered;
  }

  &--pink {
    background-color:deeppink;
  }

  &--cyan {
    background-color: #14ebff;
    color: #171a1d;
  }




}

.u-td-center {
  text-align: center !important;
}

.u-th-center {
  & > .mat-sort-header-container {
    justify-content: center !important;

    & > .mat-sort-header-content {
      margin-left: 20px !important;
    }
  }
}

.u-cursor-default {
  cursor: default;
}

::ng-deep .u-multiline-tooltip {
  white-space: pre-line;
  font-size: 1px;
  background-color: red;
}



