//http://mcg.mbitson.com/#!?mcgpalette0=%23d49803



// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

//$falck-primary-palette: (
//        50 : #ede3e5,
//        100 : #d1babf,
//        200 : #b38c95,
//        300 : #945d6a,
//        400 : #7d3b4a,
//        500 : #66182a,
//        600 : #5e1525,
//        700 : #53111f,
//        800 : #490e19,
//        900 : #38080f,
//        A100 : #ff707f,
//        A200 : #ff3d52,
//        A400 : #ff0a24,
//        A700 : #f00019,
//        contrast: (
//                50 : #000000,
//                100 : #000000,
//                200 : #000000,
//                300 : #ffffff,
//                400 : #ffffff,
//                500 : #ffffff,
//                600 : #ffffff,
//                700 : #ffffff,
//                800 : #ffffff,
//                900 : #ffffff,
//                A100 : #000000,
//                A200 : #ffffff,
//                A400 : #ffffff,
//                A700 : #ffffff,
//        )
//);

$falck-primary-palette: (
        50 : #e8ebed,
        100 : #c6ccd1,
        200 : #a0aab2,
        300 : #7a8893,
        400 : #5e6f7c,
        500 : #415565,
        600 : #3b4e5d,
        700 : #324453,
        800 : #2a3b49,
        900 : #1c2a37,
        A100 : #7cbeff,
        A200 : #49a4ff,
        A400 : #168bff,
        A700 : #007efc,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);


$falck-accent-palette: (
        50 : #ffe4e6,
        100 : #ffbcc0,
        200 : #ff8f96,
        300 : #ff626c,
        400 : #ff404d,
        500 : #ff1e2d,
        600 : #ff1a28,
        700 : #ff1622,
        800 : #ff121c,
        900 : #ff0a11,
        A100 : #ffffff,
        A200 : #fff5f5,
        A400 : #ffc2c3,
        A700 : #ffa8aa,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$falck-warn-palette: (
        50 : #faf3e1,
        100 : #f2e0b3,
        200 : #eacc81,
        300 : #e1b74f,
        400 : #daa729,
        500 : #d49803,
        600 : #cf9003,
        700 : #c98502,
        800 : #c37b02,
        900 : #b96a01,
        A100 : #fff1e2,
        A200 : #ffd8af,
        A400 : #ffbf7c,
        A700 : #ffb363,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #000000,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);





// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/





$falck-primary: mat.define-palette($falck-primary-palette, 500);
$falck-accent: mat.define-palette($falck-accent-palette, 500);
$falck-warn: mat.define-palette($falck-warn-palette);

// The warn palette is optional (defaults to red).
//$falck-warn: mat.define-palette(mat.$deep-orange-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cts-monitoring-theme: mat.define-dark-theme((
        color: (
                primary: $falck-primary,
                accent: $falck-accent,
                warn: $falck-warn,
        )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cts-monitoring-theme);

//CUSTOMIZATION
//.mat-card-header-text {
//        height: auto;
//        margin: 0 !important;
//}



//tr.mat-row {
//  height: toRem(37) !important;
//}
.mat-mdc-tooltip {
  font-size: 10px !important;
}
.mat-mdc-form-field {
  line-height: 2;
}

td.mat-mdc-cell, tr.mdc-data-table__cell, .mat-mdc-cell,
tr.mdc-data-table__cell, tr.mat-mdc-table, tr.mdc-data-table__row {
  font-size: 12px !important;
  height: 30px !important;
  padding: 4px 16px !important
}

