@import "variables";
@import "normalize.css/normalize.css";

* {
  box-sizing: border-box;
  outline: none;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: $colorGray-3; color: $colorGray-1;}

//html, body { height: 100%; font-size: $baseFontSize+px}
//body { margin: 0; font-size: $baseFontSize+px}

a {
  text-decoration: none;
  cursor: pointer;
  color: $colorAccent;

  &:visited,
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    cursor: pointer;
    color: $colorAccent;
  }
}

// Workaround fix for HotToast.
@keyframes hotToastEnterAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastEnterAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 80px, 0) scale(0.6);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes hotToastExitAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 130px, -1px) scale(0.5);
  }
}

@keyframes hotToastExitAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -130px, -1px) scale(0.5);
  }
}
