$baseFontSize: 12px;
$baseFontFamily: "IBM Plex Sans";
$toolbarHeight: 80px;

//$colorPrimary:#66182a;
$colorPrimary:#415565;
$colorAccent: #ff1e2d;
$colorSecondary: #415565;
$colorGray-1: #fefefe;
//$colorGray-2: rgba(0, 0, 0, 0.15);
$colorGray-2: #171a1d;
$colorGray-3: #303030;

$colorOk: #0db50d;
$colorWarn: #d49803;
$colorError: #d30001;



$mobileMenuMaxWidth: 1900px;
